import axios from "@axios";

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        fetchLeaves(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .get("/leaves", { params: queryParams })
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => reject(error));
            });
        },
        fetchLeaveStatus() {
            return new Promise((resolve, reject) => {
                axios
                    .get("/list/leaves-status")
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => reject(error));
            });
        },
        fetchLeave(ctx, id) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`leaves/${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        },
        fetchNHRs(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .get("/hire-request", {
                        params: {
                            perPage: 100,
                            page: 0
                        }
                    })
                    .then(response => {
                        resolve(response.data.data);
                    })
                    .catch(error => reject(error));
            });
        },
        addRecruitertoNHR(ctx, userData) {
            console.log(userData);
            return new Promise((resolve, reject) => {
                axios
                    .get(
                        `hire-request/${userData.nhrId}/assign-to-bba/${userData.recruiter}`
                    )
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => {
                        if (error.response.status === 422) {
                            if (
                                typeof error.response.data.errors !==
                                "undefined"
                            ) {
                                const keys = Object.keys(
                                    error.response.data.errors
                                );
                                const values = Object.values(
                                    error.response.data.errors
                                );
                                for (let i = 0; i <= keys.length; i += 1) {
                                    if (
                                        error.response.data.errors.hasOwnProperty.call(
                                            values,
                                            i
                                        )
                                    ) {
                                        reject(values[0][0]);
                                    }
                                }
                            }
                        } else {
                            reject(error);
                        }
                    });
            });
        },
        addLeavetoNHR(ctx, userData) {
            return new Promise((resolve, reject) => {
                axios
                    .post(`hire-request/${userData.nhrId}/assign-leaves`, {
                        leaves: userData.leaves
                    })
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => {
                        if (error.response.status === 422) {
                            if (
                                typeof error.response.data.errors !==
                                "undefined"
                            ) {
                                const keys = Object.keys(
                                    error.response.data.errors
                                );
                                const values = Object.values(
                                    error.response.data.errors
                                );
                                for (let i = 0; i <= keys.length; i += 1) {
                                    if (
                                        error.response.data.errors.hasOwnProperty.call(
                                            values,
                                            i
                                        )
                                    ) {
                                        reject(values[0][0]);
                                    }
                                }
                            }
                        } else {
                            reject(error);
                        }
                    });
            });
        },
        addLeave(ctx, userData) {
            return new Promise((resolve, reject) => {
                axios
                    .post("leaves", userData)
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => {
                        if (error.response.status === 422) {
                            if (
                                typeof error.response.data.errors !==
                                "undefined"
                            ) {
                                const keys = Object.keys(
                                    error.response.data.errors
                                );
                                const values = Object.values(
                                    error.response.data.errors
                                );
                                for (let i = 0; i <= keys.length; i += 1) {
                                    if (
                                        error.response.data.errors.hasOwnProperty.call(
                                            values,
                                            i
                                        )
                                    ) {
                                        reject(values[0][0]);
                                    }
                                }
                            }
                        } else {
                            reject(error);
                        }
                    });
            });
        },
        updateLeave(ctx, leave) {
            return new Promise((resolve, reject) => {
                axios
                    .post(`leaves/${leave.id}`, leave.data)
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => {
                        if (error.response.status === 422) {
                            if (
                                typeof error.response.data.errors !==
                                "undefined"
                            ) {
                                const keys = Object.keys(
                                    error.response.data.errors
                                );
                                const values = Object.values(
                                    error.response.data.errors
                                );
                                for (let i = 0; i <= keys.length; i += 1) {
                                    if (
                                        error.response.data.errors.hasOwnProperty.call(
                                            values,
                                            i
                                        )
                                    ) {
                                        reject(values[0][0]);
                                    }
                                }
                            }
                        } else {
                            reject(error);
                        }
                    });
            });
        },
        // updateLeaveStatus(ctx, leave) {
        //     return new Promise((resolve, reject) => {
        //         axios
        //             .post(`leaves/statusUpdate/${leave.id}`, {
        //                 status: 1
        //             })
        //             .then(response => {
        //                 resolve(response);
        //             });
        //     });
        // },
        removeLeave(ctx, userId) {
            return new Promise((resolve, reject) => {
                axios
                    .delete(`leaves/${userId}`)
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },
        removeMultipleLeaves(ctx, ids) {
            return new Promise((resolve, reject) => {
                axios
                    .post(`/deleteMultipleLeaves/`, { ids: ids })
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },
        fetchLeaveSource(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .get("leaves-source", { params: queryParams })
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => reject(error));
            });
        },
        addSource(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .post("leaves-source", { name: queryParams.name })
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => {
                        if (error.response.status === 422) {
                            if (
                                typeof error.response.data.errors !==
                                "undefined"
                            ) {
                                const keys = Object.keys(
                                    error.response.data.errors
                                );
                                const values = Object.values(
                                    error.response.data.errors
                                );
                                for (let i = 0; i <= keys.length; i += 1) {
                                    if (
                                        error.response.data.errors.hasOwnProperty.call(
                                            values,
                                            i
                                        )
                                    ) {
                                        reject(values[0][0]);
                                    }
                                }
                            }
                        } else {
                            reject(error);
                        }
                    });
            });
        },
        removeSource(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .delete(`leaves-source/${queryParams.id}`)
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => reject(error));
            });
        },
        getCustomFields() {
            return new Promise((resolve, reject) => {
                axios
                    .get("/leaves/create")
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => reject(error));
            });
        },
        addDesignation(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/designations", { name: queryParams.name })
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => {
                        if (error.response.status === 422) {
                            if (
                                typeof error.response.data.errors !==
                                "undefined"
                            ) {
                                const keys = Object.keys(
                                    error.response.data.errors
                                );
                                const values = Object.values(
                                    error.response.data.errors
                                );
                                for (let i = 0; i <= keys.length; i += 1) {
                                    if (
                                        error.response.data.errors.hasOwnProperty.call(
                                            values,
                                            i
                                        )
                                    ) {
                                        reject(values[0][0]);
                                    }
                                }
                            }
                        } else {
                            reject(error);
                        }
                    });
            });
        },
        fetchDesignationsList(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .get("/list/designations", { params: queryParams })
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => reject(error));
            });
        }
    }
};
