<template>
  <div>
    <b-card title="Leave Update" sub-title="leave details">
      <b-form autocomplete="off" @submit="formSubmitted">
        <!-- leave details tab -->
        <validation-observer ref="accountRules" tag="form">
          <b-form autocomplete="off" id="leave-form" class="p-2">
            <b-row>
              <b-col md="6">
                <b-form-group label="Leave Type" label-for="leave-type">
                  <validation-provider
                    #default="{ errors }"
                    name="Leave Type"
                    rules="required"
                  >
                    <b-form-input
                      id="leave-type"
                      v-model="data_local.type"
                      name="type"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Leave Type"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="6">
                <b-form-group label="Leave Status" label-for="leave-status">
                  <validation-provider
                    #default="{ errors }"
                    name="Leave Status"
                    rules="required"
                  >
                    <v-select
                      id="status"
                      v-model="data_local.status"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="leaveStatusOption"
                      :clearable="false"
                      placeholder="Select Status Type.."
                      :searchable="false"
                      label="label"
                      :reduce="(option) => option.value"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="Balance Level" label-for="is_unlimited">
                  <validation-provider
                    #default="{ errors }"
                    name="Balance Level"
                    rules="required"
                  >
                    <div class="demo-inline-spacing">
                      <b-form-radio
                        v-model="data_local.is_unlimited"
                        name="is_unlimited"
                        value="1"
                      >
                        Unlimited
                      </b-form-radio>
                      <b-form-radio
                        v-model="data_local.is_unlimited"
                        name="is_unlimited"
                        value="0"
                      >
                        Limited
                      </b-form-radio>
                    </div>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  label="Usablity Type Level"
                  label-for="usability_type"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Usablity Type Level"
                    rules="required"
                  >
                    <div class="demo-inline-spacing">
                      <b-form-radio
                        v-model="data_local.usability_type"
                        name="usability_type"
                        value="splittable"
                      >
                        Splittable
                      </b-form-radio>
                      <b-form-radio
                        v-model="data_local.usability_type"
                        name="usability_type"
                        value="non-splittable"
                      >
                        non-Splittable
                      </b-form-radio>
                    </div>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="6">
                <b-form-group label="Days Type" label-for="days_type">
                  <validation-provider
                    #default="{ errors }"
                    name="Days Type"
                    rules="required"
                  >
                    <div class="demo-inline-spacing">
                      <b-form-radio
                        v-model="data_local.days_type"
                        name="days_type"
                        value="working_days"
                      >
                        Working Days
                      </b-form-radio>
                      <b-form-radio
                        v-model="data_local.days_type"
                        name="days_type"
                        value="all_weekdays"
                      >
                        All Weekdays
                      </b-form-radio>
                    </div>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="Leave Cashable" label-for="leave-cashable">
                  <validation-provider
                    #default="{ errors }"
                    name="Leave Cashable"
                    rules="required"
                  >
                    <v-select
                      id="leave-cashable"
                      v-model="data_local.encashable"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="leaveCashableOption"
                      :clearable="false"
                      placeholder="Select Cashable Type.."
                      :searchable="false"
                      label="label"
                      :reduce="(option) => option.value"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="6">
                <b-form-group label="Leave Limit" label-for="leave-limit">
                  <validation-provider
                    #default="{ errors }"
                    name="Leave Limit"
                    rules="required"
                  >
                    <b-form-input
                      id="leave-limit"
                      v-model="data_local.fixed_limit"
                      name="fixed_limit"
                      type="number"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Leave Limit"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="6">
                <b-form-group
                  label="Leave Minimum Mandatory Limit"
                  label-for="leave-minimum-mandatory-limit"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Leave Minimum Mandatory Limit"
                    rules="required"
                  >
                    <b-form-input
                      id="leave-minimum-mandatory-limit"
                      v-model="data_local.minimum_mandatory_limit"
                      name="minimum_mandatory_limit"
                      type="number"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Leave Minimum Mandatory Limit"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="6">
                <b-form-group label="Leave For" label-for="leave-for">
                  <validation-provider
                    #default="{ errors }"
                    name="Leave For"
                    rules="required"
                  >
                    <v-select
                      id="leave-for"
                      v-model="data_local.for"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="leaveForOption"
                      :clearable="false"
                      placeholder="Select Leave For.."
                      :searchable="false"
                      label="label"
                      :reduce="(option) => option.value"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="6">
                <b-form-group
                  label="Require Reason To Apply"
                  label-for="require_reason_to_apply"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Require Reason To Apply"
                    rules="required"
                  >
                    <div class="demo-inline-spacing">
                      <b-form-radio
                        v-model="data_local.require_reason_to_apply"
                        name="require_reason_to_apply"
                        value="1"
                      >
                        Required
                      </b-form-radio>
                      <b-form-radio
                        v-model="data_local.require_reason_to_apply"
                        name="require_reason_to_apply"
                        value="0"
                      >
                        Not Required
                      </b-form-radio>
                    </div>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-form-group
                v-for="(item, index) in customFields"
                :key="item.id"
                class="col-md-6"
              >
                <!-- #default="{ errors }" -->
                <validation-provider
                  :name="item.name"
                  rules=""
                  #default="validationContext"
                >
                  <b-form-group
                    v-if="item.type == 'number'"
                    :label="item.label"
                    label-for="input-1"
                  >
                    <b-form-input
                      :id="item.name"
                      :name="`custom_fields_data[${item.name}-${item.id}]`"
                      :value="
                        data_local.get_customfields_data['field_' + item.id]
                      "
                      :placeholder="item.label"
                      type="number"
                      :state="getValidationState(validationContext)"
                    />
                  </b-form-group>

                  <b-form-group
                    v-if="item.type == 'text'"
                    :label="item.label"
                    label-for="input-1"
                  >
                    <b-form-input
                      :id="item.name"
                      :name="`custom_fields_data[${item.name}-${item.id}]`"
                      :value="
                        data_local.get_customfields_data['field_' + item.id]
                      "
                      :placeholder="item.label"
                      type="text"
                      :state="getValidationState(validationContext)"
                    />
                  </b-form-group>

                  <b-form-group
                    v-if="item.type == 'url'"
                    :label="item.label"
                    label-for="input-1"
                  >
                    <b-form-input
                      :id="item.name"
                      :name="`custom_fields_data[${item.name}-${item.id}]`"
                      :value="
                        data_local.get_customfields_data['field_' + item.id]
                      "
                      :placeholder="item.label"
                      type="url"
                      :state="getValidationState(validationContext)"
                    />
                  </b-form-group>

                  <b-form-group
                    v-if="item.type == 'rating'"
                    :label="item.label"
                    label-for="input-1"
                  >
                    <div class="d-flex align-items-center">
                      <star-rating
                        v-model="
                          data_local.get_customfields_data['field_' + item.id]
                        "
                        :show-rating="false"
                        :star-size="30"
                      ></star-rating>
                      <span
                        v-if="
                          data_local.get_customfields_data['field_' + item.id]
                        "
                        :class="`rating-btn btn ${
                          ratingdescription[
                            data_local.get_customfields_data['field_' + item.id]
                          ].class
                        }`"
                      >
                        {{
                          ratingdescription[
                            data_local.get_customfields_data["field_" + item.id]
                          ].text
                        }}</span
                      >
                      <input
                        type="hidden"
                        v-model="
                          data_local.get_customfields_data['field_' + item.id]
                        "
                        :name="`custom_fields_data[${item.name}-${item.id}]`"
                      />
                    </div>
                  </b-form-group>

                  <b-form-group
                    v-if="item.type == 'password'"
                    :label="item.label"
                    label-for="input-1"
                  >
                    <b-form-input
                      :id="item.name"
                      :name="`custom_fields_data[${item.name}-${item.id}]`"
                      :value="
                        data_local.get_customfields_data['field_' + item.id]
                      "
                      :placeholder="item.label"
                      type="password"
                      :state="getValidationState(validationContext)"
                    />
                  </b-form-group>

                  <b-form-group
                    v-if="item.type == 'textarea'"
                    :label="item.label"
                    label-for="input-1"
                  >
                    <b-form-textarea
                      :name="`custom_fields_data[${item.name}-${item.id}]`"
                      :value="
                        data_local.get_customfields_data['field_' + item.id]
                      "
                      trim
                      :placeholder="item.label"
                    />
                  </b-form-group>
                  <b-form-group
                    v-if="item.type == 'date'"
                    :label="item.label"
                    label-for="input-1"
                  >
                    <flat-pickr
                      :config="{ dateFormat: 'Y-m-d' }"
                      :name="`custom_fields_data[${item.name}-${item.id}]`"
                      :value="
                        data_local.get_customfields_data['field_' + item.id]
                      "
                      class="form-control"
                      placeholder="Select Date.."
                    />
                  </b-form-group>

                  <small class="text-danger">{{
                    validationContext.errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>

              <b-col cols="12" class="text-right">
                <b-button variant="success" @click="formSubmitted">
                  Update
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-form>
    </b-card>
  </div>
</template>

<script>
import { FormWizard, TabContent } from "vue-form-wizard";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import vSelect from "vue-select";

import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BForm,
  VBTooltip,
  BInputGroup,
  BInputGroupAppend,
} from "bootstrap-vue";
import { required, email, numeric } from "@validations";
import flatPickr from "vue-flatpickr-component";
import { onUnmounted } from "@vue/composition-api";
import store from "@/store";
import leaveStoreModule from "../leaveStoreModule";
import StarRating from "vue-star-rating";
import formValidation from "@core/comp-functions/forms/form-validation";

export default {
  components: {
    flatPickr,
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BInputGroupAppend,
    BInputGroup,
    BForm,
    vSelect,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    StarRating,
    flatPickr,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      ratingdescription: [
        {
          text: "Below Average",
          class: "star-poor",
        },
        {
          text: "Below Average",
          class: "star-poor",
        },
        {
          text: "Average",
          class: "star-belowAverage",
        },
        {
          text: "Good",
          class: "star-average",
        },
        {
          text: "Very Good",
          class: "star-good",
        },
        {
          text: "Excellent",
          class: "star-excellent",
        },
      ],
      is_loading: {
        submit: false,
      },
      redirectLeave: "",
      data_local: {
        type: "",
        status: "1",
        encashable: "0",
        usability_type: "splittable",
        days_type: "working_days",
        for: "all",
        minimum_mandatory_limit: "0",
        fixed_limit: "0",
        get_customfields_data: [],
      },
      customFields: [],
      leaveStatusOption: [],
      leaveCashableOption: [],
      leaveForOption: [],
    };
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = "app-leaves";

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      store.registerModule(USER_APP_STORE_MODULE_NAME, leaveStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME))
        store.unregisterModule(USER_APP_STORE_MODULE_NAME);
    });
    const { refFormObserver, getValidationState, resetForm } = formValidation();

    return {
      refFormObserver,
      getValidationState,
      resetForm,
    };
  },
  created() {
    this.getCustomFields();
    this.fetch_user_data(this.$route.params.id);
  },
  methods: {
    openWindow(link) {
      window.open(link);
    },
    fetch_user_data(userId) {
      this.$store
        .dispatch("app-leaves/fetchLeave", userId)
        .then((res) => {
          this.data_local = res.data.data;
        })
        .catch((err) => {
          console.error(err);
          // if (err.response.status === 404) {
          //   return;
          // }
          console.error(err);
        });
    },
    formSubmitted() {
      const self = this;

      this.is_loading.submit = true;
      self.data_local._method = "PATCH";
      this.$store
        .dispatch("app-leaves/updateLeave", {
          id: self.$route.params.id,
          data: self.data_local,
        })
        .then((res) => {
          this.is_loading.submit = false;
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Leave Updated",
          //     icon: "BellIcon",
          //     variant: "success",
          //     text: res.data.message,
          //   },
          // });
          this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Leave Updated',
                icon: 'BellIcon',
                variant: 'success',
                text: res.data.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 successful-info",
              hideProgressBar : true,
            });
          this.$router.push("/leaves");
        })
        .catch((error) => {
          this.is_loading.submit = false;
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Oops! Leave updating Failed",
          //     icon: "BellIcon",
          //     variant: "danger",
          //     text: error,
          //   },
          // });
          this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Oops! Leave updating Failed',
                icon: 'BellIcon',
                variant: 'danger',
                text: error,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
        });
    },
    validationForm() {
      const self = this;

      return new Promise((resolve, reject) => {
        this.$refs.accountRules.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
    getCustomFields() {
      const self = this;
      self.$store
        .dispatch("app-leaves/getCustomFields")
        .then((res) => {
          self.customFields = res.data.data;
          self.leaveStatusOption = res.data.leaveStatusOption;
          self.leaveCashableOption = res.data.leaveCashableOption;
          self.leaveForOption = res.data.leaveForOption;
        })
        .catch((error) => {
          // self.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Oops! Custom Fields Getting Failed",
          //     icon: "BellIcon",
          //     variant: "danger",
          //   },
          // });
          this.$toast({
              component: ToastificationContent,
              props: {
                title: `Oops! Custom Fields Getting Failed`,
                icon:'BellIcon',
                variant : 'danger',
                hideClose: true,
                
              },
                  },
                  {
              timeout : 3000,
              position : 'bottom-center',
              toastClassName:"error-info",
              hideProgressBar : true,
                  })
        });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
// Import FilePond styles
@import "~filepond/dist/filepond.min.css";
@import "~filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
.filepond--credits {
  display: none;
}
span.rating-btn {
  width: 130px;
  margin-left: 5px;
  padding: 2px;
  color: #fff;
  border-radius: 2px;
  font-size: 13px;
  text-align: center;
  font-weight: 700;
  -webkit-transition: 0.2s;
  transition: 0.2s;
  line-height: 25px;
}
.star-belowAverage {
  background: #f5c357;
}
.star-poor {
  background: #bababa;
}
.star-average {
  background: #f3d23e;
}
.star-good {
  background: #c1d759;
}
.star-excellent {
  background: #48964d;
}
</style>
